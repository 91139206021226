/* -------------------------- FONT ----------------------- */
/* ---------------------------------------------------------- */
/* Roboto-Black */
@font-face {
	font-family: 'Roboto';
	src: url('../media/Roboto/Roboto-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

/* Roboto-BlackItalic */
@font-face {
	font-family: 'RobotoItalic';
	src: url('../media/Roboto/Roboto-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

/* Roboto-Bold */
@font-face {
	font-family: 'RobotoBold';
	src: url('../media/Roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

/* Roboto-BoldItalic */
@font-face {
	font-family: 'Roboto';
	src: url('../media/Roboto/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

/* Roboto-Italic */
@font-face {
	font-family: 'Roboto';
	src: url('../media/Roboto/Roboto-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

/* Roboto-Light */
@font-face {
	font-family: 'Roboto';
	src: url('../media/Roboto/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

/* Roboto-LightItalic */
/* @font-face {
	font-family: 'Roboto';
		 src: url('..meidaRoboto/Roboto-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
  } */

/* Roboto-Medium */
/* @font-face {
	font-family: 'Roboto';
		 src: url('../media/Roboto/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
  } */

/* Roboto-MediumItalic */
/* @font-face {
	font-family: 'Roboto';
		 src: url('../media/Roboto/Roboto-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
  } */

/* Roboto-Regular */
/* @font-face {
	font-family: 'Roboto';
		 src: url('../meida/Roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  }
   */
/* Roboto-Thin */
/* @font-face {
	font-family: 'Roboto';
		 src: url('../meida/Roboto/Roboto-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
  } */

/* Roboto-ThinItalic */
/* @font-face {
	font-family: 'Roboto';
		 src: url('../meida/Roboto/Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
  }
  
  
  @font-face {
	font-family: 'Roboto';
		 src: url('../meida/Roboto/Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
  }
   */

/* @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-MediumItalic.eot');
	src: local('Bagoss Standard TRIAL Medium Italic'), local('BagossStandardTRIAL-MediumItalic'),
		url('../meida/Bagos/BagossStandardTRIAL-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-MediumItalic.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-MediumItalic.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-Medium.eot');
	src: local('Bagoss Standard TRIAL Medium'), local('BagossStandardTRIAL-Medium'),
		url('../meida/Bagos/BagossStandardTRIAL-Medium.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-Medium.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-Medium.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-LightItalic.eot');
	src: local('Bagoss Standard TRIAL Light Italic'), local('BagossStandardTRIAL-LightItalic'),
		url('../meida/Bagos/BagossStandardTRIAL-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-LightItalic.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-LightItalic.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-BoldItalic.eot');
	src: local('Bagoss Standard TRIAL Bold Italic'), local('BagossStandardTRIAL-BoldItalic'),
		url('../meida/Bagos/BagossStandardTRIAL-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-BoldItalic.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-BoldItalic.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-Bold.eot');
	src: local('Bagoss Standard TRIAL Bold'), local('BagossStandardTRIAL-Bold'),
		url('../meida/Bagos/BagossStandardTRIAL-Bold.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-Bold.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-Bold.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-Light.eot');
	src: local('Bagoss Standard TRIAL Light'), local('BagossStandardTRIAL-Light'),
		url('../meida/Bagos/BagossStandardTRIAL-Light.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-Light.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-Light.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-SemiBold.eot');
	src: local('Bagoss Standard TRIAL SemiBold'), local('BagossStandardTRIAL-SemiBold'),
		url('../meida/Bagos/BagossStandardTRIAL-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-SemiBold.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-SemiBold.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-ThinItalic.eot');
	src: local('Bagoss Standard TRIAL Thin Italic'), local('BagossStandardTRIAL-ThinItalic'),
		url('../meida/Bagos/BagossStandardTRIAL-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-ThinItalic.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-ThinItalic.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-Thin.eot');
	src: local('Bagoss Standard TRIAL Thin'), local('BagossStandardTRIAL-Thin'),
		url('../meida/Bagos/BagossStandardTRIAL-Thin.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-Thin.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-Thin.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-RegularItalic.eot');
	src: local('Bagoss Standard TRIAL Regular Italic'), local('BagossStandardTRIAL-RegularItalic'),
		url('../meida/Bagos/BagossStandardTRIAL-RegularItalic.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-RegularItalic.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-RegularItalic.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-RegularItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-Regular.eot');
	src: local('Bagoss Standard TRIAL Regular'), local('BagossStandardTRIAL-Regular'),
		url('../meida/Bagos/BagossStandardTRIAL-Regular.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-Regular.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-Regular.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Bagos';
	src: url('../meida/Bagos/BagossStandardTRIAL-SemiBoldItalic.eot');
	src: local('Bagoss Standard TRIAL SemiBold Italic'), local('BagossStandardTRIAL-SemiBoldItalic'),
		url('../meida/Bagos/BagossStandardTRIAL-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../meida/Bagos/BagossStandardTRIAL-SemiBoldItalic.woff2') format('woff2'),
		url('../meida/Bagos/BagossStandardTRIAL-SemiBoldItalic.woff') format('woff'),
		url('../meida/Bagos/BagossStandardTRIAL-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
  }   */

/* ---------------------------------------------------------- */

* {
	margin: 0;
	padding: 0;
	overflow: hidden;
	box-sizing: border-box;
	font-family: Roboto, Helvetica, sans-serif;
	font-size: 16px;
	color: #4d4d4d;
}

a {
    border: none;
    outline: none;
    text-decoration: none; 
}

a:focus {
    outline: none; 
}

p{
	text-align: justify;
}

/* ----------------CopyButton----------------- */
.btn-copy {
	--button-bg: #ffffff;
	--button-hover-bg: #464646;
	--button-text-color: #474747;
	--button-hover-text-color: #8bb9fe;
	--tooltip-offset: 8px;
	position: relative;
	width: 30px;
	height: 30px;
	background-color: transparent;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .cp-tooltip {
	position: absolute;
	top: -150%; 
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	visibility: hidden;
	background: var(--tooltip-bg);
	padding: 4px 8px;
	border-radius: 4px;
	font-size: 12px;
	color: black;
	white-space: nowrap;
	transition: opacity 0.2s ease-in-out;
  }
  
  .cp-tooltip::before {
	content: attr(data-text-initial);
  }
  
  .btn-copy:hover .cp-tooltip {
	opacity: 1;
	visibility: visible;
  }
  
  .cp-clipboard, .cp-check-mark {
	fill: var(--button-text-color);
  }

/* -------------- Header ------------------- */
.api-header {
	border-bottom: 1px solid #88888860;
	height: 10vh;
	width: 83.9vw;
	position: fixed;
	right: 0;
	z-index: 999;
	padding: 20px;
	background-color: #ffffff;
}

/* -------------- Sidebar ------------------- */
/* Sidebar container styling */
.api-sidebar {
	background-color: #ffffff;
	height: 100vh;
	width: 16vw;
	display: flex;
	flex-direction: column;
	position: relative;
	border-right: 1px solid #88888860;
}

.sidebar-item.disabled {
	color: gray;
	cursor: not-allowed;
	pointer-events: none;
}

/* Header and body */
.api-sidebar .sidebar-header {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 15%;
	margin-top: 20px;
}

.api-sidebar .sidebar-header img {
	height: 100%;
}

.api-sidebar .sidebar-body {
	margin: 0;
	overflow: auto;
	padding-left: 8px;
}

.sidebar-body::-webkit-scrollbar {
	width: 1px;
}

.sidebar-body::-webkit-scrollbar-track {
	background: transparent;
}

.sidebar-body::-webkit-scrollbar-thumb {
	background-color: #888888;
	border-radius: 20px;
	border: 0px;
}

.api-sidebar .sidebar-body .sidebar-section .section-title {
	font-size: 12px;
	margin: 0;
	padding-left: 10px;
	color: #a8a5a5;
	margin-top: 5px;
	margin-bottom: -10px;
}

/* Sidebar item styling */
.sidebar-item {
	display: flex;
	align-items: center;
	padding: 5px 7px;
	padding-left: 10px;
	margin: 0;
	cursor: pointer;
	color: #1c1c1c;
	transition: background-color 0.1s ease, color 0.2s ease, font-weight 0.2s ease;
	position: relative;
	font-weight: 600;
	width: 100%;
	margin-top: 5px;
}

.sidebar-item:hover {
	background-color: #f1f6fb;
	color:  #003366;
	font-family: sans-serif;
	font-weight: 600;
}

.sidebar-item:hover::after {
	content: '';
	background-color: #003366;
	width: 5px;
	height: 100%;
	position: absolute;
	right: 0;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.sidebar-item.active {
	background-color: #f1f6fb;
	color: #003366;
	font-weight: 600;
	position: relative;
	transition: background-color 0.2s ease, color 0.3s ease, padding-left 0.3s ease;
}

.sidebar-item.active::after {
	content: '';
	background-color: #003366;
	width: 5px;
	height: 100%;
	position: absolute;
	right: 0;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

/* Submenu styling */
.submenu {
	margin-left: 30px;
	margin-top: 5px;
	width: 100%;
	border-left: 3px solid #003366;
	/* background-color: #bababa3d; */
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
}

/* Closed submenu state */
.submenu.closed {
	max-height: 0;
	opacity: 0;
}

/* Open submenu state */
.submenu.open {
	max-height: 200px;
	opacity: 1;
}

/* Submenu item styling */
.submenu-item {
	display: flex;
	align-items: left;
	padding: 7px 10px;
	padding-left: 6px;
	color: #333;
	cursor: pointer;
	transition: background-color 0.1s ease, color 0.1s ease;
}

.submenu-item:hover {
	background-color: #f1f6fb;
	color:  #003366;
	font-weight: 500;
}

.submenu-item:hover::after {
	content: '';
	background-color: #003366;
	width: 5px;
	height: 100%;
	right: 0;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.submenu-item.active {
	background-color: #f1f6fb;
	color: #003366;
	font-weight: 500;
}

.submenu-item span {
	font-size: 14px;
	padding-left: 5px;
}

.items {
	margin-left: 8px;
}

.sidebar-footer {
	width: 90%;
	height: 11%;
	display: flex;
	align-items: last baseline;
	justify-content: center;
	position: relative;
}

.sidebar-footer img{
	width: 75%;
	margin: 0 auto;
}

.sidebar-toggle {
	display: none;
}

/* -------------- ApiDoc ------------------- */
.apidoc {
	position: absolute;
	top: 0vh;
	left: 16vw;
	height: fit-content;
	width: 84vw;
	padding: 0px;
	/* overflow: hidden; */
}

.apidoc .breadcrumb {
	padding-left: 24px;
}

.apidoc-header-headline {
	height: 12vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	padding-left: 24px;
	text-align: left;
	z-index: 9;
}

.apidoc-header-headline p {
	font-size: 20px;
	color: #00264c;
	padding: 0;
	text-align: justify;
}

.apidoc-header-headline h1 {
	font-size: 42px;
	color: #003366;
}

.apidoc-header-infograph {
	height: 18vh;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
}

.apidoc-header-infograph img {
	height: 100%;
	width: 100%;
}

.apidoc-body {
	width: 100%;
	height:82vh;
	padding: 0 24px;
	display: flex;
	justify-content: space-between;
	gap: 30px;
	border-top: 0.5px solid #d9d9d9;
}

.apidoc-body-mainContent {
	width: 75%;
	height: 100%;
	padding-right: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
	overflow-y: scroll;
	scroll-behavior: smooth;
}

.apidoc-body-mainContent::-webkit-scrollbar {
	width: 1px;
}

.apidoc-body-mainContent::-webkit-scrollbar-track {
	background: rgb(241, 240, 238);
}

.apidoc-body-mainContent::-webkit-scrollbar-thumb {
	background-color: #002966;
	border-radius: 20px;
}

.bodyContent-para {
	text-align: justify;
	line-height: 1.2rem;
	padding: 0;
	margin-bottom: 10px;
}

.bodyContent h3 {
	font-size: 18px;
	font-family: sans-serif;
	font-weight: 600;
	color: #00264C;
	margin-top: 15px;
}

.keyFeatures li {
	padding-left: 20px;
	padding-top: 10px;
}

.lihead {
    font-weight: bold; /* Bold for emphasis */
    white-space: nowrap; /* Prevents text in the header from wrapping */
}
.keyfeature-li{
	display: flex;
	align-items: center;
	flex-wrap: wrap; 
	gap: 5px;
}

.lihead{
	font-size: 16px;
	font-weight: 600;
	color: #003366;
}

.apidoc-note {
	border-left: 4px solid #003366;
	background-color: #f6f6f6;
	padding: 15px 10px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	color: #003366;
	font-family: sans-serif;
	font-size: 14px;
	/* margin-right: 10px; */
	margin-bottom: 20px;
}

.notePadding {
	width: 95%;
	margin: 20px auto;
}

.apiDoc-heading {
	padding: 10px 0;
	color: #003366;
	font-size: 20px;
	font-family: sans-serif;
	margin-bottom: 5px;
}

/* Payment Integration */

.bodyContent-para ul {
	margin: 10px 0;
}

.bodyContent-para ul li {
	list-style: circle;
	margin: 5px 0;
}

.bodyContent-heading {
	font-size: 20px;
	font-family: sans-serif;
	font-weight: 600;
	color: #00264C;
}

.integration-methods-list li {
	font-size: 16px;
	font-weight: 600;
	color: #4d4d4d;
	margin: 7px 0;
}

/* Table of Contents */
.apidoc-body-tableContent {
	width: 25%;
	height: fit-content;
	display: flex;
	justify-content: center;
	/* border: 0.5px solid var(--Secondary-Secondary200, rgba(229, 229, 229, 1));
	box-shadow: 0px 2px 6px 0px rgba(25, 33, 61, 0.14); */
	border: 0.25px solid var(--Secondary-Secondary200, rgba(229, 229, 229, 1));
	box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
	border-radius: 8px;
	margin-top: 20px;
}

.bodyOverview-table {
	height: fit-content;
	width: 100%;
	background-color: #ffffff;
	padding: 20px;
}

.bodyOverview-table h4 {
	color: #003366;
	font-size: 18px;
}

.bodyOverview-tableMenu {
	margin: 10px 10px;
	font-size: 15px;
}

.bodyOverview-tableMenu .items {
	font-weight: 600;
	padding: 0px 10px;
	border-radius: 4px;
	transition: .2s ease-in-out;
	color: #626262;
}

.bodyOverview-tableMenu li {
	margin: 10px 0px;
}

.bodyOverview-tableMenu li:hover {
	cursor: pointer;
	color: #003366;
}

.bodyOverview-table-Submenu-items {
	font-size: 13px;
}

.bodyOverview-table-Submenu-items li {
	margin: 0;
	padding: 5px 10px;
	border-left: 2px solid #ececec;
	transition: border-color 0.3s ease, color 0.3s ease;
}

.bodyOverview-table-Submenu-items li a {
	font-size: 12px;
}

.bodyOverview-table-Submenu-items li:hover {
	font-weight: bold;
	color: #003366;
	border-left: 3px solid #003366;
}

.bodyOverview-table-Submenu-items-submenu li a {
	margin-left: 20px;
	display: inline-block;
	/* Ensures proper text wrapping and block handling */
}

.bodyOverview-table-Submenu li.active {
	background-color: #f0f0f0;
	font-weight: bold;
	border-left: 3px solid #003366;
}

.bodyOverview-table-Submenu li.active a {
	color: #003366;
}

.secondHeading {
	padding-bottom: 10px;
}

/* .apidoc-footer {
	height: 8vh;
	width: 75%;
	padding: 20px 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.apidoc-footer div {
	display: flex;
	align-items: center;
	gap: 2px;
	cursor: pointer;
} */

/* ---------- 404 Page not found ----------- */
.error-container {
	height: 100vh;
	width: 100vw;
	padding: 20px;
}

.error-header {
	height: 15%;
	width: 80%;
	margin-top: 20px;
	margin: 0 auto;
}

.error-logo {
	height: 100%;
}

.error-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 80%;
	margin: 0 auto;
}

.error-title {
	font-size: 30px;
	font-weight: 600;
	color: #003366;
	opacity: 1;
}

.error-page-head {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	text-align: center;
}

.error-page-head p {
	font-size: 20px;
	color: #003366;
	opacity: 1;
}

.error-image {
	height: auto;
}

.primary-btn-bright {
	padding: 10px 20px;
	border-radius: 3px;
	background-color: #003366;
	border: none;
	outline: none;
	color: #ffffff;
	gap: 5px;
	min-width: 30%;
	margin: 10px auto;
	cursor: pointer;
}

.overlay-line {
	border-bottom: 2px solid #00264C;
	z-index: 2;
	position: relative;
	width: 100%;
}

.rectangle {
	position: absolute;
	width: 8%;
	height: 6%;
	border: 2px solid #e6ebf0;
	z-index: 0;
}

.rectangle-1 {
	top: 35%;
	left: 0;
}

.rectangle-2 {
	top: 41%;
	left: 2%;
}

.rectangle-3 {
	top: 47%;
	left: 0;
}

.rectangle-4 {
	top: 2%;
	left: 30%;
}

.rectangle-5 {
	top: 7%;
	left: 32%;
}

.rectangle-6 {
	top: 0%;
	left: 66%;
}

.rectangle-7 {
	top: 6%;
	left: 64%;
}

.rectangle-8 {
	top: 35%;
	left: 66%;
}

.rectangle-9 {
	top: 41%;
	left: 70%;
}

.digit {
	font-size: 350px;
	font-weight: 530;
	color: #b6d3ff;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.digit-container {
	display: flex;
	align-items: center;
	color: #b6d3ff;
	z-index: 0;
	height: 100%;
	margin-bottom: -15%;
	width: 100%;
	justify-content: center;
	padding-left: 20px;
}

.door-image {
	width: 85%;
	height: 85%;
}

/* --------------------- Short Header --------------------------- */
.ShortHeader {
	width: 100%;
	/* position: fixed;
	z-index: 9; */
	height: 18vh;
	/* left: 16vw; */
	display: flex;
	flex-direction: column;
	padding-left: 24px;
	margin: 0;
	position: relative;
	box-sizing: border-box;
	padding-bottom: 10px;
	gap: 12px;
}

.axipays-pg{
	display: none;
}

.ShortHeader .breadcrumb {
	padding-left: 2px;
}

.headerHeadline {
	margin: 0;
}

.headerHeadline p {
	font-size: 20px;
	color: #00264c;
	padding-left: 2px;
	font-family: sans-serif;
}

.headerHeadline h1 {
	font-size: 42px;
	color: #00264c;
	font-family: sans-serif;
}


/* --------------------- Main Container ------------------ */
.mainContainer {
	height: 100%;
	width: 84vw;
	position: fixed;
	top: 20vh;
	left: 16vw;
	padding: 10px;
	color: #4d4d4d;
	font-size: 14px;
	display: flex;
	overflow: auto;
}

.mainContainer h2 {
	color: #00264c;
}

.mainContainer h2,
p {
	padding: 10px;
}

.mainContainer-Content {
	width: 80%;
	height: 78vh;
	overflow: hidden;
	overflow-y: auto;
	padding-right: 10px;
}

.mainContainer-Content::-webkit-scrollbar {
	width: 5px;
	/* width of the entire scrollbar */
}

.mainContainer-Content::-webkit-scrollbar-track {
	background: transparent;
	/* color of the tracking area */

}

.mainContainer-Content::-webkit-scrollbar-thumb {
	background-color: #888888;
	/* color of the scroll thumb */
	border-radius: 20px;
	/* roundness of the scroll thumb */
	border: 0px;
	/* creates padding around scroll thumb */
}

/* ----------------------- Code Block --------------------------------- */
.codeBlock {
	height: fit-content;
	width: 100%;
	position: relative;
}

.codeBlock-heading {
	font-weight: 600;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	color: #002966;
	margin-left: 10px;
	overflow: hidden;
}

.codyBody {
	width: 95%;
	margin: 0 auto;
}

.codeBlock .codeBlock-header {
	height: 40px;
	background-color: #f2f2f2;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px 8px 0 0;
	padding: 0 20px;
}

.codeBlock .codeBlock-header .dummy-threeDots {
	display: flex;
	height: 100%;
	align-items: center;
}

.codeBlock .codeBlock-header .dummy-threeDots .dot1,
.dot2,
.dot3 {
	margin: 0 2px;
	height: 12px;
	width: 12px;
	border-radius: 50%;
}

.dot1 {
	background-color: #ff9f9f;
}

.dot2 {
	background-color: #8cc2f7;
}

.dot3 {
	background-color: #b5f197;
}

.codeBlock-header-Actions {
	display: flex;
	gap: 10px;
}

.codeBlock-header-Actions .ic {
	cursor: pointer;
	transition: transform 0.1s, color 0.2s;
}

.codeBlock-header-Actions .ic:active {
	transform: scale(0.9);
	color: #3498db;
	/* Change to your desired color */
}

.codeBlock-body {
	background-color: #f6f6f6;
	height: fit-content;
	color: white;
	width: 100%;
	border-radius: 0 0 8px 8px;
}


/* ------------------------- S2S Integration-------------------------- */

.page-content-pointers {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: end;
	margin: 10px;
	width: 100%;
	border-left: 2px dashed #b7b7b7;
}

.page-content-pointers .Steps {
	width: 96%;
	margin: 15px 5px;
}

.page-content-pointers .para{
	font-family: sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #002966;
	margin-left: 10px;
}

.codeCountainer {
	width: 98%;
}

/* .parameter-table {
	width: 95%;
	
	margin: 0 auto;
	margin-top: 30px;
} */

.scrollable-table-container {
	overflow-y: auto;
	overflow-x: auto;
	scroll-behavior: smooth;
	width: 95%;
	margin:30px auto;
}

.scrollable-table-container::-webkit-scrollbar {
	height: 2px;
}

.scrollable-table-container::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.scrollable-table-container::-webkit-scrollbar-thumb {
	background: #d9d9d9;
	border-radius: 10px;
}

.parameter-table {
	width: 100%;
	border-collapse: collapse;
}

.parameter-table th,
.parameter-table td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
	color: #4d4d4d;
	font-size: 14px;
}

.parameter-table th {
	background-color: #f4f4f4;
}

.parameter-table td {
	background-color: #fff;
}

/* Custom VS Code-like Theme Styles */

pre {
	color: #f8f8f2;
	padding: 15px;
	border-radius: 5px;
	overflow-x: auto;
	scroll-behavior: smooth;
}

pre::-webkit-scrollbar {
	height: 2px;
}

pre::-webkit-scrollbar-track {
	background: #f1f1f1;
}

pre::-webkit-scrollbar-thumb {
	background: #d9d9d9;
	border-radius: 10px;
}


.hljs-string {
	color: #dd1144;
	font-family: "Consolas";
}

.hljs-tag {
	color: #0a85ff;
}

.hljs-attr {
	color: #002bb2;
	font-family: "Consolas";
}

.hljs-number {
	color: #09885A;
	font-family: "Consolas";
}

.hljs-variable {
	color: #001080;
}

.hljs-punctuation {
	color: #09885A;
	font-family: "Consolas";
}

.hljs-punctuation {
	color: #8e8e8e;
}

.hljs-literal {
	color: #2196f3;
}

.hljs-attribute {
	color: #9e9e9e;
}

.hljs-comment {
	color: #002bb2;
	font-family: "Consolas";
}

.hljs-comment:before {
	color: #002bb2;
	font-family: "Consolas", monospace;
}

.hljs,
.hljs-subst {
	color: #002bb2;
	font-family: "Consolas";
}

.hljs-name {
	color: #002bb2;
}

.hljs-string {
	color: #dd1144;
	font-family: "Consolas", monospace;
}

.hljs-keyword,
.hljs-attribute {
	color: #7e57c2;
	font-family: "Consolas", monospace;
}

.hljs-function {
	color: #008b8b;
	font-weight: bold;
}

.endpoint {
	padding: 0 20px;
}

.get-body {
	color: #339B00;
	font-weight: bold;
	font-family: "Roboto";
}

.saparator {
	color: #B7B7B7;
	display: inline-block;
	line-height: 1.5;
	font-size: 25px;
	vertical-align: -13px;
	margin: 0 10px;
}

.base-url {
	color: #FF7F52;
	font-family: "Consolas";
}

.url {
	color: #00264C;
	font-family: "Consolas";
	margin-left: 5px;
}


/* ................. */

.breadcrumb {
	display: flex;
	align-items: center;
	color: #666;
	cursor: default;
	margin-top: 20px;
}

.breadcrumb-text,.breadcrumb-link {
	font-weight: 500;
	color: #888;
	text-decoration: none;
	font-size: 14px;
	font-family: sans-serif;
}

.breadcrumb-link{
	cursor: pointer;
}

.breadcrumb-current {
	font-weight: 600;
	color: #003366;
	font-size: 14px;
}

.breadcrumb-separator {
	margin: 0 5px;
	margin-top: 3px;
}

.breadcrumb-current.highlight {
	font-weight: bold;
	color: #003366;
	font-size: 14px;
}

.ShortHeader .header-path {
	margin-left: -30px;
}



/* .....................Responsive................ */


@media (max-width: 768px) {
	* {
		font-size: 14px;
	}

	.headerHeadline p {
		font-size: 18px;
	}

	.headerHeadline h1 {
		font-size: 30px;
	}

	.api-header .menu-icon {
		display: block;
	}

	.apidoc {
		left: 0vw;
		width: 100vw;
	}

	.apidoc-body {
		padding-left:15px;
		padding-right: 5px;
		height: 70vh;
	}

	.apidoc-body-tableContent {
		display: none;
	}

	.apidoc-body-mainContent {
		width: 100%;
	}

	.apidoc .breadcrumb {
		padding-left: 20px;
	}

	.apidoc-header-headline {
		padding-left: 20px;
		height: 10vh;
	}

	.apidoc-header-infograph {
		height: 15vh;
	}

	.breadcrumb {
		margin-top: 50px;
	}

	.api-sidebar {
		width: 250px;
		transition: transform 0.3s ease-in-out;
		background-color: #ffffff;
		z-index: 999;
	}

	.api-sidebar.open {
		transform: translateX(0);
	}

	.api-sidebar {
		transform: translateX(-100%);
	}

	.sidebar-toggle {
		display: block;
		position: fixed;
		top: 10px;
		left: 10px;
		z-index: 1000;
		cursor: pointer;
		background-color: #f2f2f2;
		border-radius: 50px;
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.api-sidebar .sidebar-header {
		justify-content: center;
	}

	.ShortHeader {
		padding-left: 20px;
		height: fit-content;
	}

	.axipays-pg{
		display: block;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 99;
		top: 0;
		width: 90%;
	}
	
	.axipays-pg img{
		height: 70%;
		width: 30%;
		margin: 0;
	}

	.ShortHeader .breadcrumb {
		padding-left: 2px;
	}

	.apidoc .breadcrumb :link,
	.apidoc .breadcrumb span,
	.ShortHeader :link,
	.ShortHeader span {
		font-size: 10px;
	}

	.breadcrumb-separator {
		margin: 0 0px;
	}

	.endpoint {
		padding: 0 0px;
	}

	.apidoc-footer {
		width: 100%;
		padding: 10px;
	}

	
	/* --------------404 Error Page----------------- */

	.error-header {
		width: 100%;
		text-align: center;
	}

	.error-body {
		width: 100%;
		height: 70%;
	}

	.rectangle {
		width: 10%;
	}

	.digit {
		font-size: 50vw;
	}

	.error-title {
		font-size: 5vw;
	}

	.error-page-head p {
		font-size: 4vw;
		white-space: nowrap;
	}

	.digit-container {
		padding-left: 0px;
		padding-right: -10px;
	}

	.door-image {
		width: 100%;
		height: 80%;
	}
}


/* ..................Tablet View............. */

@media (min-width: 768px) and (max-width: 1024px) {

	* {
		font-size: 18px;
	}

	.api-header .menu-icon {
		display: block;
	}

	.apidoc {
		left: 0vw;
		width: 100vw;
	}

	.apidoc-body {
		padding-left:15px;
		padding-right: 4px;	
		padding-bottom: 20px;
	}

	.apidoc-body-tableContent {
		display: none;
	}

	.apidoc-body-mainContent {
		width: 100%;
		padding-bottom: 50px;
	}

	.apidoc .breadcrumb {
		padding-left: 20px;
	}

	.apidoc-header-headline {
		padding-left: 20px;
		height: fit-content;
		margin-top: 20px;
	}

	.apidoc-header-infograph {
		height: fit-content;
	}

	.breadcrumb {
		margin-top: 60px;
	}

	.api-sidebar {
		width: 250px;
		transition: transform 0.3s ease-in-out;
		background-color: #ffffff;
		z-index: 999;
	}

	.api-sidebar.open {
		transform: translateX(0);
	}

	.api-sidebar {
		transform: translateX(-100%);
	}

	.sidebar-toggle {
		display: block;
		position: fixed;
		top: 10px;
		left: 10px;
		z-index: 1000;
		cursor: pointer;
		background-color: #f2f2f2;
		border-radius: 50px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.api-sidebar .sidebar-header {
		justify-content: center;
	}

	.ShortHeader {
		padding-left: 20px;
		height: fit-content;
	}

	.ShortHeader .breadcrumb {
		padding-left: 2px;
	}

	.apidoc .breadcrumb :link,
	.apidoc .breadcrumb span,
	.ShortHeader :link,
	.ShortHeader span {
		font-size: 20px;
	}

	.breadcrumb-separator {
		margin: 0 0px;
	}

	.apidoc-footer {
		width: 100%;
	}


	/* --------------404 Error Page----------------- */

	.error-header {
		width: 100%;
		text-align: center;
	}

	.error-body {
		width: 100%;
		height: 70%;
	}

	.rectangle {
		width: 10%;
	}

	.digit-container {
		padding-left: 20px;
	}

	.digit {
		font-size: 50vw;
	}

	.error-title {
		font-size: 5vw;
	}

	.error-page-head p {
		font-size: 4vw;
		white-space: nowrap;
	}

	.door-image {
		width: 100%;
		height: 85%;
		padding-right: 20px;
	}

}

.iFrame-notes {
	background-color: #F6F6F6;
	border-left: 4px solid #0a85ff;
	padding: 5px;
	color: #8c8c8c;
	margin-top: 20px;
}

.iFrame-notes h3 {
	display: flex;
	align-items: center;
	margin-left: 8px;
	color: #0a85ff;
	font-size: 14px;
}

.keyFeatures-notes{
	padding-bottom: 10px;
}
.keyFeatures-notes li {
	padding-left: 20px;
	padding-top: 10px;
	font-size: 14px;
}

.keyFeatures-notes b {
	font-weight: 600;
	font-size: 14px;
}

.bodycontent-subdiv{
	font-weight: 600;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #002966;
}

.bodycontent-li{
	margin: 0px 20px;
}

.bodycontent-li div{
	margin: 8px 0;
}

.benefits-li ul li{
	margin: 5px 3px;
}